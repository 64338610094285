import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { HTMLContent } from '../components/Content'
import KreatorPageTemplate from '../components/KreatorPageTemplate'
import Layout from '../components/Layout'
import config from '../../config'
import SE0 from '../components/SEO'

const KreatorPage = (props) => {
  const { data: { markdownRemark: { html, fields: { slug }, frontmatter: { title, meta_title, meta_description, cover, keywords } } } } = props
  const websiteSchemaOrgJSONLD = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: config.siteUrl,
    name: config.siteTitle,
    alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
  }
  for (let i=1;i<keywords.length;i++){
    if (keywords[i].charAt(0)!==' '){
    keywords[i] = ' ' + keywords[i];
    }
    //console.log(keywords[i]);
  }
  //console.log(String(keywords));
  return (
    <Layout>
      <SE0
        title={title}
        meta_title={meta_title}
        meta_desc={meta_description}
        keywords={keywords}
        cover={cover.publicURL}
        slug={slug}
        userName={config.userName}
        siteTitle={config.siteTitle}
        siteUrl={config.siteUrl}
        siteFBAppID={config.siteFBAppID}
        userTwitter={config.userTwitter}
        pathPrefix={config.pathPrefix}
      />
      <Helmet htmlAttributes={{ lang : 'en-en', dir:'ltr' }}>
      {/* Schema.org tags */}
      <script type='application/ld+json'>
        {JSON.stringify(websiteSchemaOrgJSONLD)}
      </script>

      <script type='application/ld+json'>
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://artopen.co/"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": title,
        }]
      })}
      </script>

      </Helmet>

      <KreatorPageTemplate
        contentComponent={HTMLContent}
        title={title}
        content={html}
      />
    </Layout>
  )
}

KreatorPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default KreatorPage

export const kreatorPageQuery = graphql`
  query KreatorPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
            slug
          }
      frontmatter {
        cover {
            publicURL
        }
        title
        meta_title
        meta_description
        keywords
      }
    }
  }
`
