import loadable from '@loadable/component'
import React from 'react'
import Content from '../Content'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const ContactCall = loadable(() => import('../ContactCall'))

const KreatorPageTemplate = (props) => {
  const { title, content, contentComponent } = props
  const PageContent = contentComponent || Content

  return (
    <div>
    <section className='hero is-primary is-bold' id="cal-head" style={{backgroundImage:'url(/img/kreator.svg)',backgroundSize:'contain',backgroundRepeat:'no-repeat',backgroundPosition:'top center',backgroundAttachment: `fixed`}}>
      <div className='hero-body'>
      <div className='container section'>

        <div className='columns section'>
          <div>
            <h1 className='title'>
              {title}
            </h1>
            <h2 className='subtitle'>
            Design your own unique calendar.
            </h2>
            <h3 className="stays-white">Check out our offer: </h3>
            <br />
            <br />
            <div className='columns' style={{marginLeft:'20px'}}>
            <div>
            <Link className='button-white' to="/offer/company-calendars/">
            <img width="12px"  style={{verticalAlign:'middle', marginRight:'15px'}}   alt="business calendars" src='/img/calendar-alt.svg'/>
            business calendars
            </Link>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div>
            <Link className='button-white' to="/offer/book-calendars/">
            <img width="12px"  style={{verticalAlign:'middle', marginRight:'15px'}}   alt="book calendars" src='/img/book.svg'/>
            book calendars
            </Link>
            </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </section>
    <nav className="breadcrumbs-nav section">
    <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<span style={{color:'#23C495'}}><b>{title}</b></span>
    </nav>
    <iframe async loading="lazy" style={{width: '100% !important',marginTop:'0px', height: '865px', overflow: 'visible'}} src="https://kreator.pierluigi.pl/" width="100%" height="150%"></iframe>



      <section className='section section--gradient'>

      <div className='columns'>
        <div className='column is-10 is-offset-1'>
          <div className='section'>
            <PageContent className='content' content={content} />
          </div>
        </div>
      </div>

      <div className='bottomUi' style={{textAlign:'center',marginBottom:'30px'}}>
      <Link className='button-green' style={{fontSize:'15px'}} to='/offer/'> Discover our offer&nbsp;&nbsp; <img width="12px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/> </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link className='button-green' style={{fontSize:'15px'}} to='/portfolio/'> See portfolio&nbsp;&nbsp; <img width="12px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/> </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link className='button-green' style={{fontSize:'15px'}} to='/blog/'> Read the blog&nbsp;&nbsp; <img width="12px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/> </Link>
      </div>


      </section>
      <ContactCall />

    </div>
  )
}

KreatorPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default KreatorPageTemplate
